import { createContext, useContext, useCallback, useEffect, useState } from "react";
import { useWiki } from "./WikiContext";
import { FileNode } from "../types";
import { deleteFolder, deleteFile, getFolderContents, createFolder, createFile, updateFolder, updateFile, listRepoFolders } from "@/services/Blar/Wiki";
import { Context } from "@/contexts/ContextProvider";

export const WikiFileStructureContext = createContext<WikiFileStructureContextType | null>(null);

interface WikiFileStructureContextType {
  structure: FileNode[];
  setStructure: React.Dispatch<React.SetStateAction<FileNode[]>>;
  handleDelete: (node: FileNode) => Promise<void>;
  parseResponse: (response: any) => FileNode[];
  handleCreateFolder: (name: string, parentId: number | null) => Promise<void>;
  handleCreateFile: (name: string, parentId: number | null, content: string | null) => Promise<void>;
  handleRename: (node: FileNode, newName: string) => Promise<void>;
  addNodeToStructure: (currentStructure: FileNode[], parentId: number | null, newNode: FileNode) => FileNode[];
  repoFolders: FileNode[];
  repoFolder: FileNode | null;
  setRepoFolder: (repoFolder: FileNode | null) => void;
  openFolders: { [key: string]: boolean };
  setOpenFolders: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  handleFolderToggle: (node: FileNode) => Promise<void>;
  isRepoFolder: (node: FileNode | null) => boolean;
  updateNodeInStructure: (structure: FileNode[], updatedNode: FileNode) => FileNode[];
  handleToggle: (node: FileNode) => Promise<void>;
}

export const WikiFileStructureProvider = ({ children }: { children: React.ReactNode }) => {
  const { setSelectedNode, setLoadingCount, handleFileClick, selectedNode, setTitle } = useWiki();
  const [repoFolders, setRepoFolders] = useState<FileNode[]>([]);
  const [repoFolder, setRepoFolder] = useState<FileNode | null>(null);
  const [structure, setStructure] = useState<FileNode[]>([]);
  const [openFolders, setOpenFolders] = useState<{ [key: string]: boolean }>(
    {}
  );

  const { showMessage } = useContext(Context);
  
  const handleDelete = useCallback(async (node: FileNode) => {
    try {
      setLoadingCount((prev) => prev + 1);
      if (node.type === 'folder') {
        await deleteFolder(node.id);
      } else {
        await deleteFile(node.id);
      }

      setStructure((prevStructure) => {
        const removeNode = (nodes: FileNode[]): FileNode[] => {
          return nodes.filter((n) => {
            // If this is the node to delete, filter it out
            if (n.id === node.id) return false;
            
            // If this node has children, recursively filter them
            if (n.children) {
              n.children = removeNode(n.children);
            }
            return true;
          });
        };

        return removeNode(prevStructure);
      });

      setSelectedNode(null);
      showMessage('success', 'Deleted successfully');
    } catch (error) {
      showMessage('error', 'Failed to delete');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [showMessage]);
  
  const parseResponse = useCallback((response: any): FileNode[] => {
    const folders = response.folders.map((folder: any) => ({
      id: folder.id,
      name: folder.name,
      type: 'folder',
      path: folder.path,
      parent: folder.parent,
      children: [],
    }));
  
    const files = response.files.map((file: any) => ({
      id: file.id,
      name: file.name,
      type: 'file',
      path: file.path,
      parent: file.folder,
      content: file.content,
    }));
  
    return [...folders, ...files];
  }, []);
  
  useEffect(() => {
    const fetchRepoFolders = async () => {
      setLoadingCount((prev) => prev + 1);
      try {
        const response = await listRepoFolders();
        const parsedStructure = parseResponse({"folders": response, "files": []});
        setRepoFolders(parsedStructure);
        setStructure(parsedStructure);
      } finally {
        setLoadingCount((prev) => prev - 1);
      }
    };
  
    fetchRepoFolders();
  }, [parseResponse]);

  const addNodeToStructure = (currentStructure: FileNode[], parentId: number | null, newNode: FileNode): FileNode[] => {
    const addNode = (nodes: FileNode[]): [FileNode[], boolean] => {
      let found = false;
      const updatedNodes = nodes.map(node => {
        if (node.id === parentId) {
          found = true;
          return { ...node, children: [...(node.children || []), newNode] };
        }
        if (node.children) {
          const [updatedChildren, childFound] = addNode(node.children);
          if (childFound) {
            found = true;
            return { ...node, children: updatedChildren };
          }
        }
        return node;
      });
      return [updatedNodes, found];
    };

    const [updatedStructure, found] = addNode(currentStructure);
    return found ? updatedStructure : [...currentStructure, newNode];
  };

  const handleCreateFolder = useCallback(async (name: string, parentId: number | null) => {
    try {
      setLoadingCount((prev) => prev + 1);
      const newFolder = await createFolder(name, parentId);
      const newFolderNode: FileNode = {
        id: newFolder.id,
        name: newFolder.name,
        type: 'folder',
        parent: newFolder.parent,
        path: newFolder.path,
        children: [],
      };

      setStructure(prev => addNodeToStructure(prev, parentId, newFolderNode));
      
      showMessage('success', 'Folder created successfully');
    } catch (error) {
      showMessage('error', 'Failed to create folder');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [showMessage]);

  const handleCreateFile = useCallback(async (
    name: string, 
    parentId: number | null,
    content: string | null = " "
  ) => {
    try {
      setLoadingCount((prev) => prev + 1);
      const newFile = await createFile(name, parentId, content);
      const newFileNode: FileNode = {
        id: newFile.id,
        name: newFile.name,
        type: 'file',
        parent: newFile.folder,
        path: newFile.path,
      };

      setStructure(prev => addNodeToStructure(prev, parentId, newFileNode));
      
      setSelectedNode(newFileNode);
      handleFileClick(newFileNode);
      
      showMessage('success', 'File created successfully');
    } catch (error) {
      showMessage('error', 'Failed to create file');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [handleFileClick]);

  const handleRename = useCallback(async (node: FileNode, newName: string) => {
    try {
      setLoadingCount((prev) => prev + 1);
      if (node.type === 'folder') {
        await updateFolder(node.id, {
          name: newName,
          parent: node.parent,
          id: node.id,
          path: node.path,
        });
      } else {
        await updateFile(node.id, {
          name: newName,
          content: node.content || '',
          folder: node.parent,
          id: node.id,
          path: node.path,
        });
      }

      setStructure((prevStructure) => {
        const updateNodeName = (nodes: FileNode[]): FileNode[] => {
          return nodes.map((n) => {
            if (n.id === node.id) {
              return { ...n, name: newName };
            }
            if (n.children) {
              return { ...n, children: updateNodeName(n.children) };
            }
            return n;
          });
        };
        return updateNodeName(prevStructure);
      });

      if (selectedNode && selectedNode.id === node.id) {
        setTitle(newName);
      }

      showMessage('success', 'Renamed successfully');
    } catch (error) {
      showMessage('error', 'Failed to rename');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [selectedNode, showMessage]);

  const isRepoFolder = (node: FileNode | null): boolean => {
    if (node?.type === 'folder') {
      return !!repoFolders.find(repo => repo.id === node.id);
    }
    return false;
  };

  const handleToggle = async (node: FileNode) => {
    // If the node is a folder in the root folder and it's already selected/open, close it
    if (isRepoFolder(node) && repoFolder?.id === node.id) {
      // Similar behavior to the back arrow
      setSelectedNode(null);
      setRepoFolder(null);
      setOpenFolders((prev: { [key: string]: boolean }) => ({
        ...prev,
        [node.id]: false
      }));
      return;
    }
    
    // Otherwise, proceed with normal selection
    setSelectedNode(node);
    
    if (isRepoFolder(node)) {
      setRepoFolder(node);
    }
    
    await handleFolderToggle(node);
  };

  const handleFolderToggle = async (node: FileNode) => {
    const isOpen = openFolders[node.id];
    const newOpenState = !isOpen;
    
    setOpenFolders((prev: { [key: string]: boolean }) => ({
      ...prev,
      [node.id]: newOpenState
    }));

    // Only fetch contents if we're opening an unfetched folder
    if (node.type === "folder" && newOpenState && !node.fetched) {
      try {
        setLoadingCount(prev => prev + 1);
        const response = await getFolderContents(node.id);
        const parsedChildren = parseResponse(response);
        const updatedNode = {
          ...node,
          children: parsedChildren,
          fetched: true
        };
        setStructure(prev => updateNodeInStructure(prev, updatedNode));
      } finally {
        setLoadingCount(prev => prev - 1);
      }
    }
  };

  const updateNodeInStructure = (structure: FileNode[], updatedNode: FileNode): FileNode[] => {
    return structure.map(item => {
      if (item.id === updatedNode.id) {
        // Return a new copy of the item with updated properties
        return {
          ...item,
          ...updatedNode,
        };
      }
      // If it's a folder, recursively update children
      if (item.children) {
        return {
          ...item,
          children: updateNodeInStructure(item.children, updatedNode)
        };
      }
      return item;
    });
  };

  return (
    <WikiFileStructureContext.Provider value={{ handleDelete, parseResponse, handleCreateFolder, handleCreateFile, handleRename, structure, setStructure, addNodeToStructure, repoFolders, repoFolder, setRepoFolder, openFolders, setOpenFolders, handleFolderToggle, isRepoFolder, updateNodeInStructure, handleToggle }}>
      {children}
    </WikiFileStructureContext.Provider>
  );
};


export const useWikiFileStructure = () => {
  const context = useContext(WikiFileStructureContext);
  if (!context) {
    throw new Error('useWikiFileStructure must be used within a WikiFileStructureProvider');
  }
  return context;
};
